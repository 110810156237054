import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./solid-hero.sass"
import Img from "gatsby-image"

const SolidHero = ({ title, subtitle, primary, imageFluid, imageAlt }) => {

  return <div
    className={classNames("_96", { "_9a": primary }, { "_9c": imageFluid && imageAlt })}>
    <div className={"_9d"}>
      <div className="_15">


      <h1 className={"_9f"}>{title}</h1>
      <h2 className={"_4j _4j _9e"}>{subtitle()}</h2>
      </div>
    </div>
      {imageFluid && imageAlt &&
      <div className={'_9g'}>

        <div className="_15">

        <Img imgStyle={{ "objectFit": "contain" }} className={"_9h"}
             fluid={imageFluid} alt={imageAlt}></Img>
        </div>
        </div>
      }
  </div>
}

SolidHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.func.isRequired,
  imageFluid: PropTypes.any,
  imageAlt: PropTypes.string,
  primary: PropTypes.bool,
}

export default SolidHero
